<template>
  <div>
    <b-modal
      id="add-edit-holdings"
      ref="add-edit-holdings"
      size="xl"
      :title="modalType === 'add' ? $t('holdings.addNewHolding') : $t('holdings.editHoldings')"
      :ok-disabled="!$v.writeTitle.required"
      :ok-title="modalType === 'add' ? $t('table.add') : $t('table.edit')"
      :cancel-title="$t('table.cancel')"
      :busy="isModalBusy"
      @ok.prevent="checkIfValidThenEnter"
      @hidden="hiddenHandler"
    >
      <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <b-form-group :label="$t('table.writeTitle')" label-for="input-holding-name">
          <b-form-input
            id="input-holding-name"
            v-model.trim="$v.writeTitle.$model"
            type="text"
            :placeholder="$t('table.enterTitle')"
            autofocus
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols="2" content-cols="4" :label="$t('channelModal.year')">
          <MultiSelect
            v-model="channel_settings_years"
            :options="yearsList"
            :multiple="true"
            :placeholder="$t('channelModal.pickYear')"
            label="id"
            track-by="id"
            :allow-empty="false"
            data-automatic="ms-year"
            @input="updateSettingsTable"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="multiselect__tag" :style="{ padding: option.$isDisabled ? '4px 10px' : undefined }">
                <span>{{ option.id }}</span>
                <i v-if="!option.$isDisabled" aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
              </span>
            </template>
          </MultiSelect>
        </b-form-group>
        <b-tabs v-model="active_tab" content-class="mt-3">
          <b-tab v-for="(ys, year) in channel_settings" :key="year" :title="year">
            <div class="row">
              <div class="col-6">
                <b-form-group :label="$t('table.selectChannels')">
                  <CoupleSelects
                    :add-element.sync="ys.selectAdd"
                    :delete-element.sync="ys.selectDelete"
                    :add-options="ys.options"
                    :remove-options="ys.value"
                    @leftHandler="leftHandler(ys)"
                    @rightHandler="rightHandler(ys)"
                  />
                </b-form-group>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import errorsHandler from '@/utils/errorsHandler';
import SpinnerLoader from '../SpinnerLoader.vue';
import MultiSelect from '../MultiSelect.vue';
import CoupleSelects from '../CoupleSelects.vue';
import { BTabs, BTab } from 'bootstrap-vue';

export default {
  name: 'ModalHoldings',
  components: { SpinnerLoader, MultiSelect, CoupleSelects, BTabs, BTab },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    currentItem: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      channel_settings_years: [],
      channel_settings: {},
      modalType: 'add',
      isModalLoading: true,
      isModalBusy: false,
      writeTitle: '',
      active_tab: null,
      allChannelOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      yearsList: 'getYear',
      holdings: 'getHoldings',
      holdingsStatus: 'getHoldingsStatus',
      channel: 'getChannels',
      channelsStatus: 'getChannelsStatus',
      modalEditHoldings: 'getModalEditHoldings',
    }),
    canEdit: function () {
      return this.$checkPermissions('holding.update');
    },
    canCreate: function () {
      return this.$checkPermissions('holding.create');
    },
    canDelete: function () {
      return this.$checkPermissions('holding.delete');
    },
  },
  watch: {
    async isOpen(v) {
      if (v) {
        this.showModalHoldings(this.currentItem ? 'edit' : 'add', this.currentItem);
      }
    },
  },
  validations: {
    writeTitle: {
      required,
    },
    channel_settings_years: {
      required,
    },
  },

  methods: {
    leftHandler(ys) {
      if (ys.selectAdd.id) {
        ys.options = ys.options.filter((option) => option.id !== ys.selectAdd.id);
        ys.value.unshift(ys.selectAdd);
        ys.selectAdd = '';
      }
    },
    rightHandler(ys) {
      if (ys.selectDelete.id) {
        ys.value = ys.value.filter((option) => option.id !== ys.selectDelete.id);
        ys.options.unshift(ys.selectDelete);
        ys.selectDelete = '';
      }
    },

    async showModalHoldings(type, data) {
      this.isModalLoading = true;
      this.writeTitle = '';
      this.channel_settings_years = [];
      this.allChannelOptions = [];
      this.modalType = type;
      this.$bvModal.show('add-edit-holdings');
      await this.$store.dispatch('GET_CHANNELS', { per_page: 2000, include: 'channelSettings.holding' });
      if (type === 'add') {
        this.allChannelOptions = this.channel.data;
      } else {
        await this.$store.dispatch('GET_HOLDINGS_ID', data);
        this.allChannelOptions = this.channel.data;
        this.writeTitle = this.modalEditHoldings.name;

        const channelsByYear = {};
        for (const c of this.channel.data) {
          for (const [year, data] of Object.entries(c.channel_settings)) {
            const holding = data.holding_id;
            if (holding == this.modalEditHoldings.id) {
              if (channelsByYear[+year] === undefined) {
                channelsByYear[+year] = [];
              }
              channelsByYear[+year].push(c);
            }
          }
        }
        const settings = {};
        for (const [year, channels] of Object.entries(channelsByYear)) {
          settings[year] = {
            options: this.allChannelOptions,
            value: channels,
            selectAdd: '',
            selectDelete: '',
          };
        }
        this.channel_settings = settings;
        this.channel_settings_years = Object.keys(this.channel_settings).map((x) => ({
          id: x,
          name: x,
        }));
      }
      this.isModalLoading = false;
    },

    async addEditHoldings() {
      this.isModalBusy = true;
      const name = this.writeTitle;
      const channels = {};
      for (const [key, settings] of Object.entries(this.channel_settings)) {
        channels[key] = settings.value.map((v) => v.id);
      }

      const formData = {
        id: this.modalType === 'add' ? undefined : this.modalEditHoldings.id,
        name: this.writeTitle,
        channels: channels,
      };
      await this.$store.dispatch(this.modalType === 'add' ? 'POST_HOLDINGS' : 'PUT_HOLDINGS', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalType === 'add' ? this.$i18n.t('holdings.addedHolding') : this.$i18n.t('holdings.editedHolding'),
            text: name,
          });
          this.$bvModal.hide('add-edit-holdings');
          this.writeTitle = '';
          this.channel_settings = {};
          this.$emit('submitted');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isModalBusy = false;
    },

    checkIfValidThenEnter() {
      if (this.$v.writeTitle.required && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalType === 'add'
              ? `${this.$i18n.t('holdings.confirmAddHolding')} "${this.writeTitle}"?`
              : `${this.$i18n.t('holdings.confirmEditHolding')} "${this.writeTitle}"?`,
            {
              title: this.modalType === 'add' ? this.$i18n.t('holdings.addHoldings') : this.$i18n.t('holdings.editHoldings'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-add-edit-holding-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditHoldings();
          })
          .catch((err) => {});
      }
    },

    updateSettingsTable(values) {
      const settings = { ...this.channel_settings };
      // remove settings for deselected years
      for (const [year, el] of Object.entries(settings)) {
        const yearSelected = this.channel_settings_years.find((y) => +y.id === +year);
        if (!yearSelected) {
          delete settings[year];
        }
      }
      values.forEach((y) => {
        if (y.id && !settings[+y.id]) {
          settings[+y.id] = {
            selectAdd: '',
            selectDelete: '',
            options: this.allChannelOptions,
            value: [],
          };
        }
      });
      this.channel_settings = settings;
    },

    hideModalAddEditChannels() {
      this.hiddenHandler();
      this.$bvModal.hide('add-edit-channel-modal');
    },
    hiddenHandler() {
      this.$emit('closed');
    },
  },
};
</script>
