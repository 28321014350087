<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <!--TITLE-->
        <VocabularyTitle :title="$t('holdings.holdings')" :per="paginationData.per_page" :entries="paginationData.total" />
        <!--TITLE-->
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex justify-content-between mt-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />
            <!-- <b-form-checkbox v-if="canRestore" @input="pageClick(1)" v-model="filterShowTrashed" class="mt-2" switch>
                {{$t('table.showOnlyTrashed')}}
              </b-form-checkbox> -->

            <MultiSelect
              v-model="filterYear"
              :options="yearsList"
              :multiple="false"
              :allow-empty="false"
              :placeholder="$t('channelModal.pickYear')"
              class="w-250px"
              name="id"
              track-by="id"
              label="id"
              selectLabel="id"
              data-automatic="ms-year"
              @input="callFilterDate"
            />
          </div>
          <div>
            <search-input v-model="searchElements" @searchTable="pageClick(1)" />
          </div>
        </div>

        <!--Search-->
      </div>
      <!--TABLE-->
      <div class="vocabulary-table">
        <div class="table-header sticky-vocabulary-header">
          <div class="table-header-text justify-content-start" style="flex-basis: 70%">
            <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
            {{ $t('holdings.holdings') }}
          </div>
          <div class="table-header-text justify-content-start" style="flex-basis: 30%"></div>
        </div>

        <SpinnerLoader :loading="holdingsStatus" />
        <DropDownTable
          v-for="row in tableData"
          :key="row.id"
          :row="row"
          :child-item="row.channels"
          edit-permission="holding.update"
          add-permission="false"
          delete-permission="holding.delete"
          edit-inner-permission="false"
          delete-inner-permission="false"
          @showModalAddChild="() => {}"
          @showModalEditChild="() => {}"
          @showModalDeleteChild="() => {}"
          @showModalEditParent="(data) => showModalHoldings('edit', data.data)"
          @showModalDeleteParent="(data) => showModalDeleteHoldings(data.data)"
        />
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="canCreate" variant="primary" data-automatic="add-holding-btn" @click="showModalHoldings('add')">
          {{ $t('holdings.addNewHolding') }}
        </b-button>
      </div>
      <!--FOOTER-->

      <ModalHoldings :is-open="isModalOpen" :current-item="currentItem" @closed="isModalOpen = false" @submitted="paramsData()"></ModalHoldings>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '../components/PaginateWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import SortArrows from '../components/SortArrows';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import CoupleSelects from '@/components/CoupleSelects.vue';
import DropDownTable from '../components/DropDownTable';
import MultiSelect from '@/components/MultiSelect.vue';
import ModalHoldings from '@/components/Holdings/ModalHoldings.vue';

export default {
  name: 'Holdings',
  components: {
    PaginateWrapper,
    SpinnerLoader,
    VocabularyTitle,
    SearchDropdown,
    SearchInput,
    SortArrows,
    CoupleSelects,
    DropDownTable,
    MultiSelect,
    ModalHoldings,
  },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
  },
  data() {
    return {
      filterYear: { id: +new Date().getFullYear() },
      tableData: [],
      paginationData: '',
      searchElements: '',

      isModalOpen: false,
      currentItem: null,

      value: [],
      options: [],
      filterPage: 1,
      selectedPerPage: '100',

      filterShowTrashed: false,
    };
  },

  computed: {
    ...mapGetters({
      yearsList: 'getYear',
      holdings: 'getHoldings',
      holdingsStatus: 'getHoldingsStatus',
      channels: 'getChannels',
      channelsStatus: 'getChannelsStatus',
      modalEditHoldings: 'getModalEditHoldings',
    }),
    canEdit: function () {
      return this.$checkPermissions('holding.update');
    },
    canCreate: function () {
      return this.$checkPermissions('holding.create');
    },
    canDelete: function () {
      return this.$checkPermissions('holding.delete');
    },
  },
  watch: {
    holdings() {
      this.setTableData();
    },
  },
  created() {
    document.title = this.$i18n.t('holdings.holdings') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getHoldingsInfo();
  },
  destroyed() {
    this.$store.commit('clearHoldingsList');
    this.$store.commit('clearChannelsList');
  },
  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_CHANNELS', {
        per_page: 2000,
        include: 'channelSettings.holding',
        filter: {
          'channelSettings.year_id': this.filterYear.id,
        },
      });
      await this.$store.dispatch('GET_HOLDINGS', {
        page: +this.filterPage,
        per_page: this.selectedPerPage,
        'filter[channelSettings.year_id]': this.filterYear.id,
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        sort: this.sortString ? this.sortString : null,
        'filter[trashed]': this.filterShowTrashed ? 'only' : undefined,
        include: 'channelSettings.channel',
      });
      if (this.yearsList.length == 0) this.$store.dispatch('GET_YEAR');
      this.updateQuery();
    },
    callFilterDate() {
      this.filterPage = 1;
      this.paramsData();
    },

    // pagination
    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },
    // get table info
    setTableData() {
      if (this.holdings) {
        const tableData = this.holdings.data;
        for (const holding of tableData) {
          for (const channel of this.channels.data) {
            const setting = channel.channel_settings[+this.filterYear.id];
            if (setting && setting.holding_id == holding.id) {
              if (holding.channels == undefined) holding.channels = [];
              holding.channels.push(channel);
            }
          }
        }
        this.tableData = tableData;
        this.paginationData = this.holdings.pagination;
        if (this.filterPage > this.holdings.pagination.last_page) {
          this.filterPage = this.holdings.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },
    async getHoldingsInfo() {
      await this.restoreFromQuery();
      this.paramsData();
    },

    // modal delete
    async showModalDeleteHoldings(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('holdings.confirmDeleteHolding')} "${data.name}"?`, {
          title: this.$i18n.t('holdings.deleteHoldings'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data.id;
            const name = data.name;
            this.$store.dispatch('DELETE_HOLDINGS_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('holdings.deleteHoldings'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => {
                errorsHandler(errors, this.$notify);
              },
            });
          }
        })
        .catch((err) => {});
    },

    updateSettingsTable(values) {
      // remove settings for deselected years
      for (const [year, el] of Object.entries(this.channel_settings)) {
        const yearSelected = this.channel_settings_years.find((y) => y.id === +year);
        if (!yearSelected) this.$delete(this.channel_settings, year); // delete property with reactivity on deleting years
      }
      // add settings for new selected years
      values.forEach((y) => {
        if (y.id && !this.channel_settings[y.id]) this.addYearChannelSettings(y.id);
      });
    },

    showModalHoldings(type, data) {
      this.currentItem = data;
      if (this.isModalOpen) {
        this.isModalOpen = false;
      }
      this.isModalOpen = true;
    },
  },
};
</script>

<style lang="sass"></style>
